
import './App.css';

function App() {
  return (
    <div style={{ textAlign: 'center' }}>
      <table style={{ textAlign: 'center', verticalAlign: 'top', width: '600px', maxWidth: '600px', backgroundColor: '#000000' }} width="600">
        <tbody>
          <tr>
            <td style={{ width: '596px', verticalAlign: 'top', paddingLeft: '0', paddingRight: '0', paddingTop: '15px', paddingBottom: '15px' }} width="596">
              <img
                style={{ width: '540px', maxWidth: '540px', height: '255px', maxHeight: '255px', textAlign: 'center', color: '#ffffff' }}
                src="https://i.postimg.cc/MTxdDrrY/Logos.png"
                align="center"
                width="180"
                height="85"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <img
        style={{ width: '600px', maxWidth: '600px', height: '497px', maxHeight: '497px', textAlign: 'center' }}
        alt="Hero image"
        src="https://i.postimg.cc/fyPPBfdT/Gsm.png"
        align="center"
        width="600"
        height="350"
      />

      <table style={{ textAlign: 'center', verticalAlign: 'top', width: '600px', maxWidth: '600px', backgroundColor: '#000000' }} width="600">
        <tbody>
          <tr>
            <td style={{ width: '596px', verticalAlign: 'top', paddingLeft: '30px', paddingRight: '30px', paddingTop: '30px', paddingBottom: '40px' }} width="596">
              <h1 style={{ fontSize: '20px', lineHeight: '24px', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: '600', textDecoration: 'none', color: '#efb329' }}>
                This Holiday Season
              </h1>
            </td>
          </tr>
        </tbody>
      </table>

      <img
        style={{ width: '600px', maxWidth: '600px', height: '240px', maxHeight: '240px', textAlign: 'center' }}
        alt="Image"
        src="https://i.postimg.cc/pVwF8nh2/NV-cover2.jpg"
        align="center"
        width="600"
        height="240"
      />

      <table style={{ textAlign: 'center', verticalAlign: 'top', width: '600px', maxWidth: '600px', backgroundColor: '#010101' }} width="600">
        <tbody>
          <tr>
            <td style={{ width: '596px', verticalAlign: 'top', paddingLeft: '30px', paddingRight: '30px', paddingTop: '30px', paddingBottom: '30px' }} width="596">
              <p style={{ fontSize: '15px', lineHeight: '24px', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: '400', textDecoration: 'none', color: '#ffffff' }}>
                Visit Neighborhood Vintner and show <br /> them this email to unlock <b>a special BOGO offer</b>
              </p>
              <img
                style={{ width: '180px', maxWidth: '180px', height: '85px', maxHeight: '85px', textAlign: 'center', color: '#ffffff' }}
                alt="Logo"
                src="https://i.postimg.cc/ncM1dTsJ/NV-Resign-Logo.png"
                align="center"
                width="180"
                height="85"
              />
              <p style={{ fontSize: '15px', lineHeight: '24px', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: '400', textDecoration: 'none', color: '#ffffff' }}>
                Purchase any bottle of wine and get 1 FREE bottle of
              </p>
              <h1 style={{ fontSize: '20px', lineHeight: '24px', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: '600', textDecoration: 'none', color: '#efb329' }}>
                Resign GSM wine ($35 value)
              </h1>
              <p style={{ fontSize: '15px', lineHeight: '24px', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: '400', textDecoration: 'none', color: '#ffffff' }}>
                **Limited 1 Per Person**
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{ textAlign: 'center', verticalAlign: 'top', width: '600px', maxWidth: '600px', backgroundColor: '#000000' }} width="600">
        <tbody>
          <tr>
            <td style={{ width: '596px', verticalAlign: 'top', paddingLeft: '30px', paddingRight: '30px', paddingTop: '30px', paddingBottom: '40px' }} width="596">
              <img
                style={{ width: '180px', maxWidth: '180px', height: '85px', maxHeight: '85px', textAlign: 'center', color: '#ffffff' }}
                alt="Logo"
                src="https://i.postimg.cc/hPdkGNxC/NV-Logo.png"
                align="center"
                width="180"
                height="85"
              />
              <p style={{ fontSize: '13px', lineHeight: '24px', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: '400', textDecoration: 'none', color: '#ffffff' }}>
                3663 Bee Caves Rd #4D, Austin, TX 78746
                <br />Next to Breed & Co
              </p>
              <p style={{ marginBottom: '0', fontSize: '13px', lineHeight: '24px', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: '400', textDecoration: 'none', color: '#ffffff' }}>
                <a target="_blank" style={{ textDecoration: 'underline', color: '#efb329' }} href="https://neighborhoodvintner.com/">
                  neighborhoodvintner.com
                </a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default App;
